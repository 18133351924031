import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "0aaebef3-59f0-4ae9-961f-1b06bcff6ed0",
    videoLibraryId: "123236",
    streamCdnUrl: "https://vz-727fffa7-f43.b-cdn.net",
    streamApiKey: "d9b3e6ff-2138-4a4f-b956b6ded21d-0024-4df8",
    storageApiPassword: "b0c9683e-6528-40f0-a4859e6afe34-3626-4c04",
    cdnUrl: "https://maples-pwa.b-cdn.net",
    devFolder: "/maples-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
    
    // top level menu
    assetsMenuOrder: [
        'Media',
    ],
    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],
  
    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            "256 Clarendon St",
        ],
        panoramasUrl: [
            "https://app.cloudpano.com/tours/5r0YYyhTN",
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };